<template>
    <div class="subtitle-1 text-center">
        {{ item.text }}
    </div>
</template>

<script>
export default {
    name: 'ScheduleColDay',
    props: [ 'item' ]
}
</script>

<style lang="scss">

</style>
